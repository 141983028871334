<script lang="ts" setup>
import { CustomEase } from 'gsap/all';
import StarBackground from '~/components/VisionBoard/StarBackground.vue';

const { t } = useI18n();

definePageMeta({
  authType: [AUTH_ROUTE_TYPE_PLAN_GUEST, AUTH_ROUTE_TYPE_GHOST],
  name: 'plan-index',
});

useHead({
  title: t('general.plan.landingPageTitle'),
  meta: [
    {
      name: 'title',
      content: t('general.plan.landingPageTitle'),
    },
    {
      name: 'keywords',
      content: t('general.plan.keywords'),
    },
  ],
});

const analytics = useAnalyticsService();
onMounted(() => {
  analytics.track(EVENTS.LANDING_PAGE_VIEWED);
});

const { $gsap } = useNuxtApp();

const { animationsEnabled } = storeToRefs(useAccessibilityStore());

const stopGuessing = ref<HTMLElement | null>(null);

const goToFirstNameStep = async () => {
  const toOnboardingAssistantCookie = useCookie('toOnboardingAssistant');
  toOnboardingAssistantCookie.value = 'redirect';
  await navigateTo('/onboarding');
};

const backToTop = () => {
  $gsap.to(window, {
    duration: animationsEnabled.value ? 2 : 0,
    scrollTo: 0,
    ease: CustomEase.create('ease-in-out', '0.4, 0, 0.2, 1'),
  });
};

const scrollToGetStarted = () => {
  if (stopGuessing.value) {
    $gsap.to(window, {
      duration: animationsEnabled.value ? 1 : 0,
      scrollTo: {
        y: stopGuessing.value,
        offsetY: 0,
      },
      ease: CustomEase.create('ease-in-out', '0.4, 0, 0.2, 1'),
    });
  }
};
</script>

<template>
  <div id="landing-page" class="overflow-y-auto overflow-x-hidden">
    <div>
      <div class="bg-[url('/images/onboardingLandingPageBg.webp')] bg-cover bg-center">
        <StarBackground key="home-page" :hasBackground="false">
          <header class="container absolute inset-x-0 mx-auto flex justify-between px-4 py-6">
            <CoreClusterLogo key="lockupPrimaryDarkHome" variant="lockupPrimaryDark" />
            <UIButton class="font-semibold text-white underline" text="Log in" to="/login" variant="no-style" />
          </header>
          <div
            class="flex h-dvh justify-center bg-[url('/images/onboardingLandingPageLandscape.svg')] bg-[length:150%_auto] bg-bottom bg-no-repeat px-4 py-16 md:bg-contain"
          >
            <OnboardingStart v-model="animationsEnabled" @scroll-to-get-started="scrollToGetStarted">
              <template #cta>
                <UIButton :text="$t('activities.onboarding.getStarted')" data-testid="landing-onboarding-cta" size="lg" @click="goToFirstNameStep" />
                <div class="flex gap-1 text-sm text-white">
                  <p>{{ $t('general.alreadyHaveAccount') }}</p>
                  <UIButton class="text-sm font-normal text-white underline" :text="$t('general.auth.login')" to="/login" variant="no-style" />
                </div>
              </template>
            </OnboardingStart>
          </div>
        </StarBackground>
      </div>
    </div>
    <div ref="stopGuessing">
      <LazyPlanLandingStopGuessing />
    </div>
    <LazyPlanLandingGetStarted />
    <LazyPlanLandingCareerCoach />
    <LazyPlanLandingPathways />
    <LazyPlanLandingMadeForYou />
    <div class="pb-12 text-center">
      <UIButton :text="$t('general.landing.backToTop')" variant="no-style" @click="backToTop" />
    </div>
    <div ref="footer">
      <LazyUIFooter />
    </div>
  </div>
</template>
